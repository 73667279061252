<template>
  <div class="page" v-if="formInfo">
    <el-form :model="formInfo" label-width="120px">
      <el-card>
        <template slot="header">
          <span class="title">基础信息</span>
          <div class="button-back">
            <el-button
              @click="
                () => {
                  $router.go(-1);
                }
              "
              size="small"
              class="back_btn"
            >
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>

        <el-form-item label="关卡类型" :label-width="labelWidth">
          <el-input
            size="small"
            value="整单关卡"
            disabled
            style="width: 200px"
          />
        </el-form-item>

        <el-form-item label="关联销售关卡单号" :label-width="labelWidth">
          <el-input
            size="small"
            v-model="formInfo.sn"
            disabled
            style="width: 200px"
          />
        </el-form-item>

        <el-form-item label="应用类型" :label-width="labelWidth">
          <el-select
            size="small"
            placeholder="请选择应用类型"
            v-model="formInfo.app_type"
            style="width: 200px"
            disabled
          >
            <el-option
              v-for="item in cardTypes"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户名称" :label-width="labelWidth">
          <el-input
            size="small"
            v-model="formInfo.client_name"
            disabled
            style="width: 200px"
          />
        </el-form-item>
      </el-card>

      <el-card>
        <template slot="header">
          <span class="title">关联卡号数据</span>
        </template>
        <div>
          <order-table
            type="close"
            keyName="shop_close_sell_dos"
            :disabled="true"
            :orderList="orderList"
          ></order-table>
          <el-form-item label="备注信息">
            <el-input
              size="small"
              type="textarea"
              v-model="formInfo.remark"
              rows="5"
              resize="none"
              disabled
            />
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import cardList from "@/views/tools/sale-card-open/components/cardList";
import { createCardTypes } from "@/views/tools/sale-card-open/utils/type";
import orderTable from "@/views/tools/sale-card-open/components/orderTable";
import { changeDataList } from "@/views/tools/sale-card-open/utils/span";

export default {
  name: "closeAllDetail",
  components: {
    cardList,
    orderTable,
  },
  data() {
    return {
      activeName: "all",
      labelWidth: "140px",
      cardTypes: [...createCardTypes("all")],
      id: this.$route.query.id,
      sn: this.$route.query.sn,
      filterColumn: ["统一折扣率设置"],
      formInfo: null,
      orderList: [],
    };
  },
  mounted() {
    API_saleCard.closeCardDetails({
      sn: this.sn,
      oper_type: 2,
    }).then((res) => {
      this.formInfo = res;
      this.orderList = res.shop_close_sell_dos;
    });
  },
};
</script>

<style scoped lang="scss">
@import "../style/index.scss";
</style>
